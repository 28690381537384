import {
  FacilityStatus,
  EquipmentTypeStatus,
  KeyRequestStatus,
  KeyHolderType,
  IssuedKeyStatus,
  RealTimeLogCategory,
  TroubleTicketStatus,
  TroubleTicketPriority,
  LERRequestFormStep,
  SuspensionStatus,
  ContractorStatus,
  SubstationTimeStatus,
  KeyType,
  OmsStatus,
  AuthorizationType,
  TroubleJobStatus,
  LEREmailType,
  LERRequestStatus,
  FacilityType,
  AccessRole,
  RoleEmpRoleStatus,
  LERNotificationStatus,
  lerRequestFormStep,
  TroubleTicketRequestType,
} from 'src/constants';
import Lang from './label.language';

export default {
  FORM_LABEL: {},
  FORM_VALIDATION: {},
  STATUS: {
    [FacilityStatus.ACTIVE]: 'Active',
    [FacilityStatus.INACTIVE]: 'Inactive',
    [FacilityStatus.FUTURE]: 'Future',
    // Error: duplicate property name KeyRequestStatus.APPROVED = 'Active'
    // [KeyRequestStatus.APPROVED]: 'Approved',
    [KeyRequestStatus.PENDINGAPPROVAL]: 'Pending Approval',
    [KeyRequestStatus.REJECTED]: 'Rejected',
    [KeyHolderType.EMPLOYEE]: 'Employee',
    [KeyHolderType.CONTRACTOR]: 'Contractor',
    [TroubleTicketStatus.OPEN]: 'Open',
    [TroubleTicketStatus.CLOSED]: 'Closed',
    // Error: duplicate property name TroubleTicketStatus.ACTIVE = 'Active'
    // [TroubleTicketStatus.ACTIVE]: 'Active',
    [TroubleTicketPriority.EMERGENCY]: 'Emergency',
    [TroubleTicketPriority.HIGH]: 'High',
    [TroubleTicketPriority.NORMAL]: 'Normal',
    [SuspensionStatus.SUSPENDED]: 'Suspended',
    [SuspensionStatus.AUTHORIZED]: 'Authorized',
    [ContractorStatus.ACTIVE]: 'Active',
    [ContractorStatus.INACTIVE]: 'Inactive',
    [SubstationTimeStatus.NOT_CHECKOUT_WITHIN_THE_DAY]: 'In',
    [SubstationTimeStatus.LESS_THAN_A_DAY]: 'In',
    [SubstationTimeStatus.PAST_MIDNIGHT]: 'In',
    [SubstationTimeStatus.OUT]: 'Out',
    [OmsStatus.APPROVED]: 'Approved',
    [OmsStatus.SCHEDULED]: 'Scheduled',
    [OmsStatus.SUBMITTED]: 'Submitted',
    [OmsStatus.CHANGE_REQUEST]: 'Change Request',
    [OmsStatus.IN]: 'In',
    // Error: duplicate property name
    // [OmsStatus.OUT]: 'Out',
    // [TroubleJobStatus.OPEN]: 'Open',
    // [TroubleJobStatus.CLOSED]: 'Closed',
    [TroubleJobStatus.DEVELOPING]: 'Developing',
    [RoleEmpRoleStatus.ACTIVE]: 'Active',
    [RoleEmpRoleStatus.INACTIVE]: 'Inactive',
  },
  LER_NOTIFICATION_STATUS: {
    [LERNotificationStatus.APPROVED]: 'Approved',
    [LERNotificationStatus.PENDING]: 'Pending',
    [LERNotificationStatus.REJECTED]: 'Rejected',
  },
  EQUIPMENT_TYPE_STATUS: {
    [EquipmentTypeStatus.ACTIVE]: 'Active',
    [EquipmentTypeStatus.INACTIVE]: 'Inactive',
  },
  KEY_REQUEST_STATUS: {
    [KeyRequestStatus.APPROVED]: 'Approved',
    [KeyRequestStatus.PENDINGAPPROVAL]: 'Pending Approval',
    [KeyRequestStatus.REJECTED]: 'Rejected',
  },
  ISSUED_KEY_STATUS: {
    [IssuedKeyStatus.ACTIVE]: 'Active',
    [IssuedKeyStatus.LOST]: 'Lost',
    [IssuedKeyStatus.DAMAGED]: 'Damaged',
    [IssuedKeyStatus.PENDINGRETURN]: 'Pending Return',
    [IssuedKeyStatus.RETURNED]: 'Returned',
    [IssuedKeyStatus.NOTRETURNED]: 'Not Returned',
  },
  ISSUED_KEY_HOLDER_TYPE: {
    [KeyHolderType.EMPLOYEE]: 'Employee',
    [KeyHolderType.CONTRACTOR]: 'Contractor',
  },
  REAL_TIME_LOG_STATUS: {
    [RealTimeLogCategory.FORCEDOUTAGE]: 'Forced Outage',
    [RealTimeLogCategory.NIGHTSHIFT]: 'Nightshift Checklist',
    [RealTimeLogCategory.TSOSTAFFING]: 'TSO Staffing',
    [RealTimeLogCategory.REALTIMEREQUEST]: 'Real-Time Request',
    [RealTimeLogCategory.RCOPERATING]: 'RC Operating Instruction',
    [RealTimeLogCategory.BAOPERATING]: 'BA Operating Instruction',
    [RealTimeLogCategory.EXTASSISTANCE]: 'External Assistance Request',
    [RealTimeLogCategory.CHANGECONTROL]: 'Change Control Center',
    [RealTimeLogCategory.RCBANOTIF]: 'RC/BA Notification',
    [RealTimeLogCategory.INTFREQEXCUR]: 'Int./Freq. Excursion',
    [RealTimeLogCategory.FIELDNOTIF]: 'Field Notification',
    [RealTimeLogCategory.GENOPERATING]: 'Gen. Operating Instruction',
    [RealTimeLogCategory.EMERGENCYEVENT]: 'Emergency Event',
    [RealTimeLogCategory.COMMFAILURE]: 'Comm. Failure',
    [RealTimeLogCategory.COMPTOOLS]: 'Computer Tools Issues',
    [RealTimeLogCategory.WILDFIRE]: 'Wildfire',
    [RealTimeLogCategory.CONTROLEQUIPOUTAGE]:
      '30+min Unplanned Telemetering/Control Equip Outage',
  },
  TROUBLE_TICKET_STATUS: {
    [TroubleTicketStatus.OPEN]: 'Open',
    [TroubleTicketStatus.ACTIVE]: 'Active',
    [TroubleTicketStatus.CLOSED]: 'Closed',
  },
  TROUBLE_TICKET_PRIORITY: {
    [TroubleTicketPriority.EMERGENCY]: 'Emergency',
    [TroubleTicketPriority.HIGH]: 'High',
    [TroubleTicketPriority.NORMAL]: 'Normal',
  },
  TROUBLE_TICKET_TYPE: {
    [TroubleTicketRequestType.SCHEDULED]: 'Scheduled',
    [TroubleTicketRequestType.TROUBLE]: 'Trouble',
  },
  LER_REQUEST_STEP: {
    [LERRequestFormStep.OUTAGE_FACILITY]: 'Outage Facility',
    [LERRequestFormStep.OUTAGE_DATE]: 'Outage Dates',
    [LERRequestFormStep.JOB_INFORMATION]: 'Job Information',
    [LERRequestFormStep.CREW_INFORMATION]: 'Crew Information',
    [LERRequestFormStep.AUTHORIZATION_STATEMENT]: 'Authorization Statement',
    [LERRequestFormStep.ISO]: 'ISO',
    [LERRequestFormStep.REVIEW]: 'Review and Submit',
    [LERRequestFormStep.SWITCHING]: 'Switching',
    [LERRequestFormStep.SYSTEM_CHANGE]: 'System Change',
    [LERRequestFormStep.COST]: '$$$',
  },
  SUSPENSION_STATUS: {
    [SuspensionStatus.SUSPENDED]: 'Suspended',
    [SuspensionStatus.AUTHORIZED]: 'Authorized',
  },
  CONTRACTOR_STATUS: {
    [ContractorStatus.ACTIVE]: 'Active',
    [ContractorStatus.INACTIVE]: 'Inactive',
  },
  TROUBLE_JOB_STATUS: {
    // [TroubleTicketStatus.OPEN]: 'Open',
    // [TroubleTicketStatus.CLOSED]: 'Closed',
    [TroubleJobStatus.OPEN]: 'Open',
    [TroubleJobStatus.CLOSED]: 'Closed',
    [TroubleJobStatus.DEVELOPING]: 'Developing',
  },
  SUBSTATION_ENTRY_STATUS: {
    [SubstationTimeStatus.NOT_CHECKOUT_WITHIN_THE_DAY]: 'In',
    [SubstationTimeStatus.PAST_MIDNIGHT]: 'In',
    [SubstationTimeStatus.LESS_THAN_A_DAY]: 'In',
    [SubstationTimeStatus.OUT]: 'Out',
  },
  KEY_TYPE: {
    [KeyType.KEY_30]: '30 Key',
    [KeyType.KEY_40]: '40 Key',
  },
  OMS_STATUS: {
    [OmsStatus.APPROVED]: 'Approved',
    [OmsStatus.SCHEDULED]: 'Scheduled',
    [OmsStatus.SUBMITTED]: 'Submitted',
    [OmsStatus.CHANGE_REQUEST]: 'Change Request',
    [OmsStatus.IN]: 'In',
    [OmsStatus.OUT]: 'Out',
  },
  OUTAGE_TYPE_CODE: {
    [AuthorizationType.Clearance]: 'E',
    [AuthorizationType.HotLineOrder]: 'HL',
    [AuthorizationType.Hold]: 'H',
    [AuthorizationType.OkOrNear]: 'IC',
    [AuthorizationType.Ok]: 'OK',
    [AuthorizationType.Unavailable]: 'UN',
    [AuthorizationType.Restricted]: 'R',
    [AuthorizationType.InfoOnly]: 'IO',
    [AuthorizationType.LimitedClearance]: 'LC',
    [AuthorizationType.External]: 'Ex',
    [AuthorizationType.OkStartProgram]: 'OS',
    [AuthorizationType.InterCoClearance]: 'I',
  },
  OUTAGE_TYPE_DESCRIPTION: {
    [AuthorizationType.Clearance]: 'Clearance',
    [AuthorizationType.HotLineOrder]: 'Hot Line Order',
    [AuthorizationType.Hold]: 'Hold',
    [AuthorizationType.OkOrNear]: 'Ok or Near',
    [AuthorizationType.Ok]: 'Start Program',
    [AuthorizationType.Unavailable]: 'Unavailable',
    [AuthorizationType.Restricted]: 'Restricted',
    [AuthorizationType.InfoOnly]: 'Info Only',
    [AuthorizationType.LimitedClearance]: 'Limited Clearance',
    [AuthorizationType.External]: 'External',
    [AuthorizationType.OkStartProgram]: 'Ok - Start Program',
    [AuthorizationType.InterCoClearance]: 'InterCo Clearance',
  },
  LER_REQUEST_SECTION: [
    'Outage Dates',
    'Job Info',
    'Crew',
    'Authorization Statement',
    'ISO',
    'Switching',
    'System Change',
    'Costs',
  ],
  LER_EMAIL_TYPE: {
    [LEREmailType.NOTIFICATION]: 'Notification',
    [LEREmailType.APPROVAL]: 'Approval',
  },

  LER_REQUEST_STATUS: {
    [LERRequestStatus.Cancelled]: 'Cancelled',
    [LERRequestStatus.Completed]: 'Completed',
    [LERRequestStatus.Created]: 'Created',
    [LERRequestStatus.Scheduled]: 'Scheduled',
    [LERRequestStatus.Pending]: 'Pending Approval',
    [LERRequestStatus.Submitted]: 'Submitted',
    [LERRequestStatus.Study]: 'Study',
    [LERRequestStatus.In]: 'In',
    [LERRequestStatus.Out]: 'Out',
    [LERRequestStatus.Approved]: 'Approved',
    [LERRequestStatus.Rejected]: 'Rejected',
    [LERRequestStatus.Reviewed]: 'Reviewed',
  },

  LER_REQUEST_STATUS_CODE: {
    [LERRequestStatus.Cancelled]: 'CA',
    [LERRequestStatus.Completed]: 'CM',
    [LERRequestStatus.Created]: 'CR',
    [LERRequestStatus.Scheduled]: 'S',
    [LERRequestStatus.Pending]: 'P',
    [LERRequestStatus.Submitted]: 'SB',
    [LERRequestStatus.Study]: 'St',
    [LERRequestStatus.In]: 'I',
    [LERRequestStatus.Out]: 'O',
    [LERRequestStatus.Approved]: 'A',
    [LERRequestStatus.Rejected]: 'Rj',
    [LERRequestStatus.Reviewed]: 'RV',
  },

  FACILITY_TYPE: {
    [FacilityType.Line]: 'Line',
    [FacilityType.Station]: 'Station',
  },

  FACILITY_EMAIL_TYPE: [
    'OK - Start Program',
    'Hot Line Order',
    'Info Only',
    'InterCo Clearance',
    'External',
    'Hold',
    'Restricted',
    'Unavailable',
  ],

  LER_VERSION_DISPLAY_FIELDS: {
    [lerRequestFormStep.OUTAGE_FACILITY]: [
      'outageType.outageTypeDesc',
      'facility.outgFacNm',
      'facility.facTyp.facTypNm',
      'substation.name',
      'facility.volt.voltNm',
    ],
    [lerRequestFormStep.JOB_INFORMATION]: [
      'switchInMin',
      'switchOutMin',
      'shortDescriptions',
      'recalTm',
      'rimsProjectId',
      'woNbr',
      'wrkDesc',
      'jobLoc',
      'jobNotes',
      'radializedSubs',
      'rimsProjectPhase',
      'facTbsCoord',
      'clsdGrndDsconctInd',
      'testVoltageApplied',
    ],
    [lerRequestFormStep.CREW_INFORMATION]: [
      'crew.firstName',
      'crewCellNbr',
      'crewPageNbr',
      'crewTruckNbr',
      'requestor.firstName',
      'requestorEmail',
      'authorized',
      'requestorContact',
      'supervisor.firstName',
    ],
    [lerRequestFormStep.AUTHORIZATION_STATEMENT]: [
      'authStmnt',
      'cancelNote.noteTxt',
      'dailyNotes',
    ],
    [lerRequestFormStep.ISO]: ['isoWorkKindId'],
    otherDetails: [
      'cancelType.cancelTypeDesc',
      'cancelor.fullName',
      'outageReject.rejectionReason',
      'outageReject.rejector.fullName',
      'updatedByEmployee.fullName',
      'updatedAt',
      'requestStat',
      'changeReqStat',
    ],
    radializedSubs: 'substationId',
    shortDescriptions: 'description',
    dailyNotes: 'notes',
    description: 'shortDesc',
    category: 'category',
  },

  ROLES_HOME_CALENDAR_DATA: {
    icon: 'calendar',
    description: 'View the scheduled transmission system outage plan.',
    path: '/outage-calendar',
  },

  ROLES_HOME_DATA: {
    [AccessRole.MCC_OUTAGE_SCHEDULER]: {
      icon: 'calendar',
      description:
        'Request and manage electrical transmission system outages (LER).',
      path: '/outage-planning',
    },
    [AccessRole.MCC_OUTAGE_REQUESTOR]: {
      icon: 'calendar',
      description:
        'Request and manage electrical transmission system outages (LER).',
      path: '/outage-planning',
    },
    [AccessRole.MCC_OPERATIONAL_ENGINEER]: {
      icon: 'cogs',
      description:
        'Request and manage electrical transmission system outages (LER).',
      path: '/outage-planning',
    },
    [AccessRole.MCC_REAL_TIME_OPERATOR]: {
      icon: 'clock',
      description:
        'Request and manage electrical transmission system outages (LER).',
      path: '/outage-planning',
    },
    [AccessRole.ROLES_ADMINISTRATOR]: {
      icon: 'user',
      description: 'MCC roles assignment and application management module.',
      path: '/roles',
    },
    [AccessRole.GCC_KEY_ADMINISTRATOR]: {
      icon: 'key',
      description:
        'Manage & request Schlage 30 & 40 series keys used to access field and substation electrical equipment.',
      path: '/keys',
    },
    [AccessRole.GCC_KEY_REQUESTOR]: {
      icon: 'key',
      description:
        'Manage & request Schlage 30 & 40 series keys used to access field and substation electrical equipment.',
      path: '/keys',
    },
    [AccessRole.GCC_SUBENTRY_LOG_UPDATE]: {
      icon: 'clipboard check',
      description:
        'Log of who has checked into and checked out of SDG&E electrical substations.',
      path: '/substation-entry-log',
    },
    [AccessRole.GCC_SUBENTRY_LOG_VIEW]: {
      icon: 'clipboard check',
      description:
        'Log of who has checked into and checked out of SDG&E electrical substations.',
      path: '/substation-entry-log',
    },
    [AccessRole.GCC_AUTH_LIST_ADMIN]: {
      icon: 'id card',
      description:
        'Manage Authorization and Substation Entry trainings for employees and contractors.',
      path: '/auth-list',
    },
    [AccessRole.REAL_TIME_LOG_VIEWER]: {
      icon: 'clock',
      description: 'Create and review operator logs and sick list  (RPT6020)',
      path: '/real-time-log',
    },
    [AccessRole.REAL_TIME_LOG_UPDATER]: {
      icon: 'clock',
      description: 'Create and review operator logs and sick list  (RPT6020)',
      path: '/real-time-log',
    },
    [AccessRole.MCC_OUTAGE_VIEWER]: {
      icon: 'cogs',
      description:
        'Request and manage electrical transmission system outages (LER).',
      path: '/outage-planning',
    },
    [AccessRole.TROUBLE_TICKET_REQUESTOR]: {
      icon: 'cogs',
      description:
        'Create and manage trouble tickets for electric transmission facilities, lines, and substations.',
      path: '/trouble-ticket',
    },
    [AccessRole.TROUBLE_TICKET_RESOLVER]: {
      icon: 'cogs',
      description:
        'Create and manage trouble tickets for electric transmission facilities, lines, and substations.',
      path: '/trouble-ticket',
    },
    [AccessRole.TROUBLE_TICKET_VIEWER]: {
      icon: 'cogs',
      description:
        'Create and manage trouble tickets for electric transmission facilities, lines, and substations.',
      path: '/trouble-ticket',
    },
    [AccessRole.MCC_SUBMITTING_SUPERVISOR]: {
      icon: 'cogs',
      description:
        'Request and manage electrical transmission system outages (LER).',
      path: '/outage-planning',
    },
    [AccessRole.TROUBLE_JOB_VIEWER]: {
      icon: 'cogs',
      description:
        'Create and manage trouble jobs requiring crew response for electric transmission facilities, lines, and substations via Field Service Delivery.',
      path: '/trouble-jobs',
    },
    [AccessRole.TROUBLE_JOB_UPDATER]: {
      icon: 'cogs',
      description:
        'Create and manage trouble jobs requiring crew response for electric transmission facilities, lines, and substations via Field Service Delivery.',
      path: '/trouble-jobs',
    },
    [AccessRole.GCC_AUTH_LIST_VIEWER]: {
      icon: 'id card',
      description:
        'Manage Authorization and Substation Entry trainings for employees and contractors.',
      path: '/auth-list',
    },
  },

  FORM_LER_REQUEST: {
    outgTypId: Lang.LBL_AUTHORIZATION_TYPE,
    outgFacId: Lang.LBL_LINE,
    substationId: Lang.LBL_SUBSTATION,
    wrkDesc: Lang.LBL_WORK_DESC,
    jobLoc: Lang.LBL_JOB_LOC,
    facTbsCoord: Lang.LBL_TB_COORDINATE,
    recalTm: Lang.LBL_RECALL_TIME,
    clsdGrndDsconctInd: Lang.LBL_GROUND_STATEMENT,
    testVoltageApplied: Lang.LBL_TEST_VOLTAGE,
    crewId: Lang.LBL_CREW_INFO_NAME,
    crewCellNbr: Lang.LBL_INFO_CELLPHONE_NO,
    supvId: Lang.LBL_REQUESTOR_SUPERVISOR,
    fldckBy: Lang.LBL_GF_CA_CHECK_BY,
    caisoId: 'CAISO Equipment ID',
    switchId: 'Switch ID',
    emsId: 'EMS ID',
    isoWorkKindId: Lang.LBL_NATURE_OF_WORK,
    facilityType: Lang.LBL_FACILITY_TYPE,
    voltId: Lang.LBL_EQUIPMENT_VOLTAGE,
    facTypId: Lang.LBL_EQUIPMENT_TYPE,
    primIsoCauseCode: Lang.LBL_PRIMARY_OUTAGE_CAUSE_CODE,
    secondIsoCauseCode: Lang.LBL_SECONDARY_OUTAGE_CAUSE_CODE,
    rimsProjectId: Lang.LBL_RIMS_PROJECT_ID,
    contingencyPlanInd: Lang.LBL_CONTINGENCY_PLAN_DEVELOPED.replace('?', ''),
    outageDates: Lang.LBL_OUTAGE_DATES,
    requestorId: Lang.LBL_OUTAGE_REQUESTOR_NAME,
  },

  FORM_OUTAGE_DATE: {
    startTm: 'Start Time',
    stopTm: 'End Time',
  },
  FACILITY_TYPE_ID_BACKEND: {
    LINE: 2,
    GENERATOR: 19,
  },
};
